.main-container {
    max-width: 1100px;
    border: 0px solid red;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
}

.input-text-body {
    height: 90px;
}

.header-area {
    display: flex;
    justify-content: space-between
}

.body-area {
    margin-top: 40px;
}

.login-container {
    max-width: 400px;
    border: 0px solid red;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.login-form {
    margin-top: 60px;
}

.login-btn-area {
    display: flex;
    align-items: center;

}

.history-data-item {
    margin: 8px;
    padding: 5px;
}

.history-user-question {
    font-size: 14px;
}

.history-user-answer {}

.history-header {
    display: flex;
    justify-content: space-between;
}

.history-date-picker {
    display: flex;
}

.id-area {
    display: flex;
    flex-direction: column;
}